<template>
  <div class="settings-view">
    <van-nav-bar
      title="配置XAUUSD高频交易参数"
      left-text="返回"
      left-arrow
      @click-left="onBack"
    />
    <div class="content">
      <van-field v-model="minLot" label="最小手数：" placeholder="请输入最小手数" type="number" clearable />
      <van-field v-model="maxLot" label="最大手数：" placeholder="请输入最大手数" type="number" clearable />
      <van-field v-model="bollingerPeriod" label="布林带周期：" placeholder="请输入布林带周期" type="number" clearable />
      <van-field v-model="bollingerStdDev" label="布林带标准差：" placeholder="请输入布林带标准差" type="number" clearable />
      <van-field v-model="baseStopLoss" label="基础止损点数：" placeholder="请输入止损点数" type="number" clearable />
      <van-field v-model="baseTakeProfit" label="基础止盈点数：" placeholder="请输入止盈点数" type="number" clearable />
      <van-field v-model="maxOpenOrders" label="最大未平仓订单数：" placeholder="请输入订单数" type="number" clearable />
      <van-field v-model="volumeThreshold" label="交易量门槛：" placeholder="请输入交易量门槛" type="number" clearable />
      <van-field v-model="adxPeriod" label="ADX趋势判断周期：" placeholder="请输入ADX周期" type="number" clearable />
      <van-field v-model="adxThreshold" label="ADX趋势判断阈值：" placeholder="请输入ADX阈值" type="number" clearable />

      <van-button type="primary" block @click="confirmSettings">
        确认运行
      </van-button>
    </div>

    <!-- 圆角弹窗（底部） -->
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="popup-content">
        <p>参数配置已设置成功</p>
        <p>由于1人一台VPS服务器，为此24小时之内会自动运行；如有疑问联系微信号：jbw8023949</p>
        <van-button type="primary" @click="closePopup">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { NavBar, Field, Button, Popup, showToast } from 'vant';
import axios from 'axios';

export default {
  name: 'SettingsView',
  components: {
    'van-nav-bar': NavBar,
    'van-field': Field,
    'van-button': Button,
    'van-popup': Popup
  },
  data() {
    return {
      minLot: 0.01,
      maxLot: 0.1,
      bollingerPeriod: 20,
      bollingerStdDev: 2.0,
      baseStopLoss: 100.0,
      baseTakeProfit: 100.0,
      maxOpenOrders: 10,
      volumeThreshold: 500.0,
      adxPeriod: 14,
      adxThreshold: 25.0,
      showPopup: false
    };
  },
  methods: {
    onBack() {
      this.$router.back(); // 返回上一页
    },
    confirmSettings() {
      const authCode = this.$route.query.authCode;
      const mt4Account = this.$route.query.mt4Account;
      const mt4Password = this.$route.query.mt4Password;
      const validUntil = this.formatValidUntil(this.$route.query.validUntil);

      if (!authCode || !mt4Account || !mt4Password || !validUntil) {
        showToast('缺少授权码、账户或密码信息');
        return;
      }

      // 提交配置到后端
      axios.post('https://www.cupbettingworld.com/api/settings/save', {
        authCode,
        mt4Account,
        mt4Password,
        minLot: this.minLot,
        maxLot: this.maxLot,
        bollingerPeriod: this.bollingerPeriod,
        bollingerStdDev: this.bollingerStdDev,
        baseStopLoss: this.baseStopLoss,
        baseTakeProfit: this.baseTakeProfit,
        maxOpenOrders: this.maxOpenOrders,
        volumeThreshold: this.volumeThreshold,
        adxPeriod: this.adxPeriod,
        adxThreshold: this.adxThreshold,
        validUntil // 格式化后的授权码有效期
      })
      .then(response => {
        if (response.data.success) {
          showToast('参数已成功保存');
          this.showPopup = true;
        } else {
          showToast('保存失败，请重试');
        }
      })
      .catch(error => {
        showToast('保存失败，请检查网络连接');
        console.error('保存错误:', error);
      });
    },
    formatValidUntil(validUntil) {
      const date = new Date(validUntil);
      return date.toISOString().slice(0, 19).replace('T', ' ');
    },
    closePopup() {
      this.showPopup = false; // 关闭弹窗
    }
  }
};
</script>

<style scoped>
.settings-view {
  padding: 16px;
  background-color: #f8f8f8;
}

.content {
  margin-top: 20px;
}

.van-field {
  margin-bottom: 16px;
}

.popup-content {
  padding: 16px;
  text-align: center;
}

.popup-content p {
  margin-bottom: 10px;
}
</style>
