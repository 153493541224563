<template>
  <div class="home-view">
    <van-nav-bar
      title="XAUUSD高频交易策略"
      left-text="返回"
      left-arrow
      @click-left="onBack"
    />

    <div class="form-container">
      <van-field
        v-model="authCode"
        label="授权码："
        placeholder="请输入授权码"
        clearable
      />
      <van-field
        v-model="mt4Account"
        label="MT4账户："
        placeholder="请输入MT4账户"
        type="number"
        clearable
      />
      <van-field
        v-model="mt4Password"
        label="MT4密码："
        placeholder="请输入MT4密码"
        type="password"
        clearable
      />
      <van-button type="primary" block @click="onAuthorize">
        授权验证
      </van-button>

      <div class="purchase-info">
        <p class="purchase-text">添加微信号购买授权码</p>
        <img class="wechat-image" src="@/assets/怀中人.jpg" alt="微信二维码" />
      </div>

      <div class="notice-info">
        <p class="notice-title">注意：</p>
        <p class="notice-text">1、XAUUSD高频交易仅是策略，不能百分百保证盈利；</p>
        <p class="notice-text">2、布林带突破：如果价格突破布林带上下轨，且交易量超过动态阈值，则可能进行反向操作；</p>
        <p class="notice-text">3、市场趋势判断：通过ADX指标判断市场是否有明显趋势；</p>
        <p class="notice-text">4、交易量控制：策略对市场的交易量做出了灵活的调整，增加市场活跃时的交易量控制，减少市场不活跃时的过度交易；</p>
        <p class="notice-text">5、手数动态调整：策略根据账户权益和市场条件动态计算手数，并根据市场趋势调整交易手数，减小风险；</p>
      </div>

      <van-button type="info" block @click="goToAbout">
        实操案例展示
      </van-button>
    </div>
  </div>
</template>

<script>
import { showToast, showFailToast } from 'vant';
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      authCode: '',
      mt4Account: '',
      mt4Password: ''
    };
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    async onAuthorize() {
      if (!this.authCode || !this.mt4Account || !this.mt4Password) {
        showFailToast('请填写所有信息');
        return;
      }

      try {
        const response = await axios.post('https://www.cupbettingworld.com/api/auth/verifyAuthCode', {
          username: this.mt4Account,
          password: this.mt4Password,
          authCode: this.authCode
        });

        if (response.data.success) {
          showToast({
            message: `授权码有效期至：${response.data.validUntil}`,
            type: 'success'
          });

          this.$router.push({
            name: 'settings',
            query: {
              authCode: this.authCode,
              mt4Account: this.mt4Account,
              mt4Password: this.mt4Password,
              validUntil: response.data.validUntil
            }
          });
        } else {
          showFailToast('授权码无效或已过期');
        }
      } catch (error) {
        showFailToast('验证失败，请检查输入信息');
        console.error('验证错误:', error);
      }
    },
    goToAbout() {
      this.$router.push({ name: 'about' });
    }
  }
};
</script>

<style scoped>
.home-view {
  padding: 16px;
  background-color: #f8f8f8;
}
.form-container {
  margin-top: 20px;
}
.van-field {
  margin-bottom: 16px;
}
.purchase-info {
  text-align: center;
  margin-top: 20px;
}
.purchase-text {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.wechat-image {
  width: 150px;
  height: 205px;
  border-radius: 8px;
}
.notice-info {
  margin-top: 20px;
  color: #d9534f;
}
.notice-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.notice-text {
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 5px;
}
.van-button + .van-button {
  margin-top: 16px;
}
</style>
