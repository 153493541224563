<template>
    <div class="welcome-container">
      <div class="welcome-text">
        欢迎使用高频交易策略
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Welcome',
    mounted() {
      // 动画时间为 3 秒，之后跳转到 /home 页面
      setTimeout(() => {
        this.$router.push('/home');
      }, 4000); // 3000 毫秒，即 3 秒
    }
  };
  </script>
  
  <style scoped>
  .welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e3a8a;
    color: #fff;
    font-size: 2em;
    animation: fadeOut 3s ease forwards; /* 3 秒的淡出动画 */
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  </style>
  