import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Welcome from '../views/Welcome.vue';
import SettingsView from '../views/SettingsView.vue'; // 导入新的 SettingsView 组件

const routes = [
  {
    path: '/',
    redirect: '/welcome' // 默认重定向到欢迎页面
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      title: '配置XAUUSD高频交易参数'
    }
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('../views/AdminLogin.vue'),
    meta: {
      title: '管理员登录'
    }
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () => import('../views/AdminDashboard.vue'),
    meta: {
      title: '管理员后台',
      requiresAuth: true // 需要登录后才能访问
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 动态修改页面标题和路由守卫
router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title || '默认标题';

  // 检查路由是否需要登录
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    next({ name: 'AdminLogin' }); // 重定向到登录页面
  } else {
    next();
  }
});

export default router;
